var curvature = 0.5;

function init(map, restLatLng, deliverLatLng) {

    var pos1 = new google.maps.LatLng(restLatLng.lat, restLatLng.lng);
    var pos2 = new google.maps.LatLng(deliverLatLng.lat, deliverLatLng.lng)

    function updateCurveMarker() {

        // var projection = map.getProjection();
        // var p1 = projection.fromLatLngToPoint(pos1); // xy
        // var p2 = projection.fromLatLngToPoint(pos2);


        // var e = (p2.x - p1.x, p2.y - p1.y); // endpoint (p2 relative to p1)
        // var m = (e.x / 2, e.y / 2); // midpoint
        // var o = (e.y, -e.x); // orthogonal
        // var c = ( // curve control point
        //     m.x + curvature * o.x,
        //     m.y + curvature * o.y);

        // var pathDef = 'M 0,0 ' + 'q ' + c.x + ',' + c.y + ' ' + e.x + ',' + e.y;

        // var zoom = map.getZoom();
        // var scale = 1 / (Math.pow(2, -zoom));

        // var symbol = {
        //     path: pathDef,
        //     scale: scale,
        //     strokeWeight: 1,
        //     fillColor: 'none'
        // };

        var lineSymbol = {
            path: 'M 0,-1 0,1',
            strokeOpacity: 1,
            strokeWeight: 2,
            scale: 4
        };

        var line = new google.maps.Polyline({
            path: [pos1, pos2],
            strokeOpacity: 0,
            strokeColor: '#fc6f7f',
            icons: [{
                icon: lineSymbol,
                offset: '0',
                repeat: '4%'
            }],
            map: map
        });
    }

    google.maps.event.addListener(map, 'projection_changed', updateCurveMarker);
    google.maps.event.addListener(map, 'zoom_changed', updateCurveMarker);

}
